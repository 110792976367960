<template>
  <!-- <v-card> -->
  <v-footer dark padless bottom>
    <div class="txt">
      <p>서울 서초구 서초동 1628-8</p>
      <p>대표 : 황세안<br/>
        회사명 : (주)프렌들리 | 개인정보보호책임자 : 황세안<br/>
        사업자등록번호 : 836-86-00887</p>
      <p>E-Mail : onethecon@naver.com</p>
    </div>

    <div class="link">
      <v-btn v-for="icon in icons" :key="icon" class="link_con white--text" icon>
        <v-icon size="24px">
          {{ icon }}
        </v-icon>
      </v-btn>
    </div>

    <div class="copy">
      <p>Coptright 2023 All Rights Reserved</p>
    </div>
  </v-footer>
  <!-- </v-card> -->
</template>

<script>
export default {
  name: 'Page',
  components: {
  },
  data() {
    return {
      icons: [
        'mdi-instagram',
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
      ],
    } // return 
  }, // data

  computed: {
    // ...mapState('G_NAME', [])
  }, // computed

  mounted() {
  }, // mounted

  methods: {
  }, // methods

} // export 

</script>

<style lang="scss" scoped>
// mixin
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer {
  width: 100%;
  height: auto;
  padding: 30px 20px 0;
  background: #424242;

  .txt {
    width: 100%;
    height: auto;
    @include flex-column;
    gap: 20px;

    p {
      font-size: 0.875rem;
      font-weight: 400;
      color: #fff;
      line-height: 25px;
    }
  }

  .link {
    width: 100%;
    height: auto;
    padding: 30px 0 20PX;
    border-bottom: 1px solid #555;
    display: flex;
    gap: 20px;

    &_con {
      width: auto;
      height: auto;
    }
  }

  .copy {
    width: 100%;
    height: auto;
    padding: 20px 0;

    p {
      font-size: 0.875rem;
      font-weight: 400;
      color: #fff;
    }
  }
}
</style>
